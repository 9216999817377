import {
  Button,
  ButtonGroup,
  Floater,
  TButtonGroupItem,
  useWindowScroll,
} from '@ingeniorforeningen/figurine-core';
import { useEffect, useState } from 'react';

const StickyProgramAndSignup = ({
  heroPresent = false,
  program,
  signup,
  isActive,
}: {
  heroPresent?: boolean;
  program?: Record<string, any>;
  signup?: Record<string, any>;
  isActive?: boolean;
}) => {
  if (!isActive) {
    return null;
  }

  const [{ y }] = useWindowScroll();
  const [isInHero, setIsInHero] = useState(heroPresent);
  const buttons: TButtonGroupItem[] = [];

  const getUrl = (route: Record<string, any>) => route?.route?.path || route?.url || '/';

  if (program) {
    buttons.push({
      text: program.title as string,
      href: getUrl(program),
      icon: 'ListDetails',
    });
  }

  if (signup) {
    buttons.push({
      text: signup.title as string,
      href: getUrl(signup),
      icon: 'UserPlus',
    });
  }

  useEffect(() => {
    if (y <= 10) {
      setIsInHero(heroPresent);
    } else {
      setIsInHero(false);
    }
  }, [y]);

  return (
    <Floater
      sticky
      position={{
        bottom: 0,
      }}
      px="md"
      pb="xl"
      my="xl"
      hiddenFrom="sm"
      style={{ zIndex: 99 }}
    >
      {buttons.length === 1 ? (
        <Button
          {...buttons[0]}
          iconPosition="left"
          fullWidth
          variant={isInHero ? 'transparent' : 'default'}
        />
      ) : (
        <ButtonGroup variant={isInHero ? 'transparent' : 'default'} buttons={buttons} fullWidth />
      )}
    </Floater>
  );
};

export default StickyProgramAndSignup;
